import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

interface RouteStorageObject {
    snapshot: ActivatedRouteSnapshot;
    handle: DetachedRouteHandle;
}

export class CustomRouteReuseStategy implements RouteReuseStrategy {

    storedRoutes: { [key: string]: RouteStorageObject } = {};
    private acceptedRoutes: string[] = ["dashboard",  "home", "profile", "settings"];

    /**
     * This method is called everytime we navigate between routes. The future is the route we are leaving (because we may come back to this route later) and current is the route we are about to land. If it returns TRUE the routing will not happen (which means that routing has not changed). If it returns FALSE then the routing happens and the rest of the methods are called.
    */
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        const result = future.routeConfig === current.routeConfig;

        // console.log('shouldReuseRoute', result)
        return result
    }

    /**
     * This method is called if shouldAttach returns TRUE, provides as parameter the current route (we just land), and returns a stored RouteHandle. If returns null has no effects. We can use this method to get any stored RouteHandle manually. This is not managed by the framework automatically. It is our responsibility to implement it
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        const path = this.getPath(route)
        let result: any

        // return null if the path does not have a routerConfig OR if there is no stored route for that routerConfig
        if (!route.routeConfig || !this.storedRoutes[path])
            result = '';
        else
            result = this.storedRoutes[path].handle;

        // console.log("retrieve", result);
        return result
    }

    /**
     * This method is called for the route just opened when we land on the component of this route. Once component is loaded this method is called. If this method returns TRUE then retrieve method will be called, otherwise the component will be created from scratch
    */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route)
        let result: boolean = false
        // this will be true if the route has been stored before
        let canAttach: boolean = !!route.routeConfig && !!this.storedRoutes[path];

        // this decides whether the route already stored should be rendered in place of the requested route, and is the return value
        // at this point we already know that the paths match because the storedResults key is the route.routeConfig.path
        // so, if the route.params and route.queryParams also match, then we should reuse the component
        if (canAttach) {
            let paramsMatch: boolean = this.compareObjects(route.params, this.storedRoutes[path].snapshot.params);
            let queryParamsMatch: boolean = this.compareObjects(route.queryParams, this.storedRoutes[path].snapshot.queryParams);

            result = paramsMatch && queryParamsMatch;
        } else {
            result = false;
        }

        // console.log('shouldAttach', result)
        return result
    }

    /**
     * It is invoked when we leave the current route. If returns TRUE then the store method will be invoked
    */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route)
        let result: boolean

        if (this.acceptedRoutes.indexOf(path) > -1)
            result = true;
        else
            result = false;

        // console.log('shouldDetach', result)
        return result
    }

    /**
     * This method is invoked only if the shouldDetach returns true. We can manage here how to store the RouteHandle. What we store here will be used in the retrieve method. It provides the route we are leaving and the RouteHandle
    */
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        let storedRoute: RouteStorageObject = {
            snapshot: route,
            handle: handle
        };

        const path = this.getPath(route)

        this.storedRoutes[path] = storedRoute;
        // console.log('store', storedRoute)
    }

    private getPath(route: ActivatedRouteSnapshot) {
        return route?.routeConfig?.path || ''
    }

    private compareObjects(base: any, compare: any): boolean {

        // loop through all properties in base object
        for (let baseProperty in base) {

            // determine if comparrison object has that property, if not: return false
            if (compare.hasOwnProperty(baseProperty)) {
                switch (typeof base[baseProperty]) {
                    // if one is object and other is not: return false
                    // if they are both objects, recursively call this comparison function
                    case 'object':
                        if (typeof compare[baseProperty] !== 'object' || !this.compareObjects(base[baseProperty], compare[baseProperty])) { return false; } break;
                    // if one is function and other is not: return false
                    // if both are functions, compare function.toString() results
                    case 'function':
                        if (typeof compare[baseProperty] !== 'function' || base[baseProperty].toString() !== compare[baseProperty].toString()) { return false; } break;
                    // otherwise, see if they are equal using coercive comparison
                    default:
                        if (base[baseProperty] != compare[baseProperty]) { return false; }
                }
            } else {
                return false;
            }
        }

        // returns true only after false HAS NOT BEEN returned through all loops
        return true;
    }

}