import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { Observable, throwError, retryWhen, concatMap, delay, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GenericErrorDialogComponent } from '../generic-error-dialog/generic-error-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JamaatConfig } from 'src/environments/jamaatconfig';
import { ProgressService } from './progress.service';

const TOKEN_HEADER_KEY = 'x-access-token';
export const retryCount = 3;

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private token: TokenStorageService,
    private progressService: ProgressService,
    private router: Router,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private jamaatConfig: JamaatConfig
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressService.show();
    let authReq = req;

    // Check if it's a GET request to /api/tenant and modify the header accordingly
    if (!req.url.endsWith('/api/tenant/')) {
      authReq = req.clone({
        setHeaders: {
          'x-tenant-id': this.jamaatConfig.currentConfig().tenantName
        }
      });
    }

    const token = this.token.getToken();

    if (token != null) {
      authReq = authReq.clone({ headers: authReq.headers.append(TOKEN_HEADER_KEY, token) });
    }
    return next.handle(authReq).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((error, count) => {
            if (count <= retryCount && error.status == 0) {
              return of(error);
            }
            return throwError(error);
          }),
          delay(1000)

        )
      )
    ).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (this.router?.url !== '/login' && error.status == 401) {
          this.token.signOut();
          this.router.navigate(['/login']);
          this.ngZone.run(() => {
            this.snackBar.open("Session expired. Please login again", "dismiss", { duration: 3000 });
          })
          //alert("Unauthorised request. Logging out");

        }

        else if (error.status >= 500) {
          this.ngZone.run(() => {
            this.dialog.open(GenericErrorDialogComponent, { data: error });
          })
        }
        else if (error.status == 0) {

          this.ngZone.run(() => {
            this.snackBar.open("Unable to connect to internet. Please try again", "dismiss", { duration: 10000 });
          })
        }

        return throwError(error);
      }),
      finalize(() => {
        this.progressService.hide(); // Hide progress after request (success or error)
        // request completed - doesn't matter either if it is failed or not.
      })
    )
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
];
