import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUpdateService } from './services/app-update.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { ProgressService } from './services/progress.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {

  title = 'FMB';
  // keep default to Apple, then we override if platform is different

  constructor(
    private appUpdate: AppUpdateService,
    private router: Router,
    private authService: AuthService

  ) {

    if (environment.production)
      window.console.log = function () { };   // disable any console.log debugging statements in production mode

    // if member UUID saved, which means it's an APP, send it directly to member screen. No hoops through login and login->app
    // this saves the temporary flash of login screen on slow device/load time
    if (authService.getMemberUUID()) {
      router.navigate([`m/${authService.getMemberUUID()}`]);
    }

  }

  ngOnInit() {

    this.appUpdate.initialize();
    this.appUpdate.checkForUpdates();
    // SplashScreen.hide();
  }

  ngAfterContentInit(): void {
    this.appUpdate.setAppStatusBarBackground(); // one more time after app launch
  }


}
