import { Component, OnInit } from '@angular/core';
import { AppUpdateService } from 'src/app/services/app-update.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-update-required',
  templateUrl: './app-update-required.component.html',
  styleUrls: ['./app-update-required.component.scss']
})
export class AppUpdateRequiredComponent implements OnInit {

  // set default store name as apple, we override when it's android later
  storeName = 'App Store';
  appUrl = environment.appVersion.appleAppUrl;


  constructor(private appService: AppUpdateService) { }

  ngOnInit(): void {

    //alert(this.appService.platform)
    
    if (this.appService.platform === 'android') {

      this.storeName = 'Google Play'
      this.appUrl = environment.appVersion.googleAppUrl;
    }

  }

  redirectToStore() {
    window.open(this.appUrl, "_blank")

  }

}
