
<div class="container" >

    <img class="logo" width="200px" src="assets/fmb-logo.png" >

    <h2>Update Required</h2>

    <p>This App version is no longer compatible.</p>
    <p> Please update to the latest version. </p>

    <button mat-raised-button color="primary" (click)="redirectToStore()">Open {{storeName}}</button>
</div>
